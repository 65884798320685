<template>
  <g>
    <svg:style>
      .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#handle-gradient);
      }
    </svg:style>
    <linearGradient
      data-name="inox"
      id="inox-gradient"
      x1="69.91"
      y1="204.3"
      x2="69.91"
      y2="72.64"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient" x1="69.91" y1="199.41" x2="69.91" y2="97.53" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient
      data-name="inox"
      id="inox-gradient-2"
      x1="69.91"
      y1="237.09"
      x2="69.91"
      y2="109.18"
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="69.91" y1="232.21" x2="69.91" y2="214.06" xlink:href="#glass-gradient"/>
    <linearGradient
      data-name="inox"
      id="inox-gradient-3"
      x1="69.91"
      y1="87.75"
      x2="69.91"
      y2="39.84"
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" x1="69.91" y1="82.88" x2="69.91" y2="64.73" xlink:href="#glass-gradient"/>
    <linearGradient id="handle-gradient" x1="11.16" y1="148.47" x2="16.73" y2="148.47" xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V24">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-3"
                :points="`${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 92.64} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 204.3} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 204.3} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 92.64} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 92.64}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-4"
                :points="`${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 97.53} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 199.41} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 199.41} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 97.53} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 97.53}`"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 209.18} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 209.18} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 237.09} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 237.09} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 209.18}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2"
                data-name="glass"
                class="cls-6"
                :points="`${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 214.06} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 214.06} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 232.21} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 232.21} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 214.06}`"/>
      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-7"
                :points="`${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 87.75} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 87.75} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 59.84} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 59.84} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 87.75}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3"
                data-name="glass"
                class="cls-8"
                :points="`${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 82.88} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 82.88} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 64.73} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 64.73} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 82.88}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
